.tools{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.5rem!important;

}

.gm-main select{
    box-shadow: unset!important;
}
.table_coupon_icon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAMCAYAAABbayygAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAE/SURBVChTRZGx64JQEMdPSxehpa3/QJr9AxrqjwganVukNRzcmoIWCRrcBP+BoCGaXB2aW2pxKFNEkfr+vAP7feG9d/fe57h3d8rj8YCmacT6fD6kqirVdU29Xo/6/T4pikJ5nhN9v1+wOKAsS7FbUM5OLQhiw/M8GIaB2WyG9/stj/f7HY7j4Ha7oaoqUJqmGAwGmEwmaLMjiiIBp9Op+EmSoCgKEKcbj8fQdV3W5XLBYrEQ6Hg8SpCAbMRxjOVyiTAMYdu2QKfTSSD+7+v1AjHdabVaCbTb7RAEAQ6Hg4DP5/MfXK/XAu33eymObV7b7RbcmV/VfLnZbCTIsiyYponRaIT5fC53Avq+D9d1JZLFKbmw4XCI8/mMpmnAU5BHdhjsmn69XqWXrCzLoMjWjqn1ZVzdyWNlm8eaZRn9AdhSS26cCH+uAAAAAElFTkSuQmCC);
    height: 14px;
    background-repeat: no-repeat;
    width: 13px;
    padding: 3px 7px;
    margin-top: -2px;
    vertical-align: sub;
}
.bp5-table-truncated-text{
    max-height:fit-content!important;
    text-align: left;
}

.checkin-main .bp5-input-group .bp5-button{
    padding: 7px 7px!important;
}
.tools input{
    height:29px!important;
}

/* ::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)!important;

	background-color: #F5F5F5!important;
}

::-webkit-scrollbar
{
	width: 12px!important;
	background-color: #F5F5F5!important;
}

::-webkit-scrollbar-thumb
{

	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)!important;
	background-color: #555!important;
}
 */

 .checkin-main .col-65 {
    height: 91vh !important;
    overflow: hidden;
}

.waivertext{
    text-align: left!important;
}
.waivertext p{
    border: unset!important;
}
.activeCheckIn{
    padding: 20px;
    overflow: auto;
    height: 100%;
}

.border-20{
    border-radius: 20px!important;
}

.customButton{
    border-radius: 20px;
    border: none;
    margin: 4px;
    padding: 8px 11px 8px 10px;
}

.addnote{
    background:rgb(13, 128, 80);
    color: #fff;
}
.removenote{
    background:rgb(187, 34, 34);
    color: #ecebeb;     
}
.Militarybtn{
    background:rgb(0, 38, 78);
    color: #fff;
}
.Carsbtn{
    background:rgb(172, 147, 27);
    color: #fff;
}
.Bannedbtn{
    background:rgb(234, 0, 0);
    color: #fff;
}
.UnBannedbtn{
    background:rgb(141, 141, 141);
    color: #fff;
}
.Checkedbtn{
    background:rgb(234, 151, 0);
    color: #fff; 
}

.Uncheckedbtn{
    background:rgb(161, 161, 161);
    color: #fff; 
}

.Theftrevokebtn{
    background:rgb(161, 161, 161);
    color: #fff; 
}

.revokeCarsbtn{
    background:rgb(161, 161, 161);
    color: #fff;  
}

.revokeMilitarybtn{
    background:rgb(161, 161, 161);
    color: #fff; 
}

.waiverbtn{
    background:rgb(13, 71, 240);
    color: #fff;  
}

.m-5{
    margin: 5px;
}

.ml-auto{
    margin-left: auto;
}
.text-left{
    text-align: left;
}
.activeCheckIn .bp5-tag{
    padding: 0px!important;
}

.activeCheckIn  .bp5-tag::before, .bp5-tag > *{
margin-right: 0px!important;
}
.couponbtn{
    width: 35%;
    border-radius:0px  8px 8px 0px!important;
}
.tagbtn{
    width: 35%;
    border-radius: 8px  0px 0px 8px!important;
}
.tagcss{
    border-radius: 8px!important;
}

.rowxTag{
    padding-top:5px;
    display: -webkit-inline-box;
    width: 100%;
    column-count: 2;
    
}
.rowx{
    padding-top:15px;
    display: block;
    width: 100%;
}


.activeCheckIn{
    color: #fff;
}
select{
    box-shadow: 1px 5px 10px rgb(13 71 240/24%)!important;
}
.text-light{
    /* color: #000000; */
    font-weight: 400;
}
.text-right{
text-align: right;
float: right;
}
.col-50{
    display: block;
    width: 49%;
}
.selectBusiness{
    /* border: 1px solid #d4d3d3!important; */
    margin: 3px!important;
    border-radius: 0px!important;
    padding: 7px!important;
}

.m-4{
    margin: 3px 0px 3px 0px;
}

.f-21{
    font-size: 18px;
}
.parentDiv{
    display: flex;
    /* border: 1px solid #d1cdcd; */
    border-radius: 5px;
    margin: 3px;
    background-color: rgb(155, 155, 155);
}



.parentDiv button:disabled{
    color: #f1efef!important;
}
.parentDivforfont{
    display: inline-flex;
    border: 1px solid #d1cdcd;
    border-radius: 5px;
    margin: 3px;
    background-color: rgb(155, 155, 155);
}


.child1{
    margin: auto;
    padding: 5px;
    color:#fff;
}
.child2{
    margin: auto;
    padding: 8px;
    background-color: #fff;
    border-radius: 0px 4px 4px 0px;
    color: black;
}
.parentDivsecond{
    display: flex;
}


.child3{
    border-radius: inherit;
    margin: auto;
    padding: 6px;
    background-color:#fff;
    color: black;
    width: 40px;
    font-size: 15px;

}

.tools :focus{

    outline: unset!important;
}

.checkin-main .pageselect{
    margin: 5px!important;
}

.checkin-main .bp5-input-group{
    margin: unset!important;
    margin-top:auto!important;
    margin-bottom:auto!important;
    margin-right:5px!important;
}

.bp5-input-group .bp5-button{
    min-height: 29px!important;
    min-width: 30px!important;
}

.br-50{
border-radius: 50%!important;
}


.bordersett{
    border: 1px solid #dfdfdf;
    margin: 1px;
}

.col-65 .bp5-table-quadrant{
    /* overflow: auto!important; */
    /* transform: rotateX(180deg)!important; */
}
.col-65  .bp5-table-quadrant-main .bp5-table-quadrant-scroll-container{
    /* transform: rotateX(180deg); */
}
.col-65  .bp5-table-quadrant-main{
    z-index: 2!important;
}
.col-65  .bp5-table-quadrant-scroll-container{
    /* overflow: unset!important; */
}
/* 
.col-65 .bp5-table-quadrant-body-container{
    height: 67vh;
    overflow-x: hidden;
    overflow-y: auto;

} */

.checkin-main .col-65{
    background-color: unset;
}