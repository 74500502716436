.group-crud-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  


  
  .group-table {
    width: 100%;
    border-collapse: collapse;
  }

  .badgea{
    background: #00a12be3;
    padding: 3px;
    border-radius: 10%;
    color: cornsilk;
  }

  .badgei{
    background: #a10000a8;
    padding: 3px;
    border-radius: 10%;
    color: cornsilk;
  }
  
  .group-table th,
  .group-table td {
    border: 1px solid #ddd;
    padding: 10px;
  }
  
  .group-table th {
    background-color:#c7c7c7;
  }
  
  
  
  /* Modal Styling */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: left;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content form input,
  .modal-content form select {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
 
  