div:where(.swal2-container) .swal2-html-container{
    text-align: unset!important;
}

.datepicker .bp5-input-group{
    margin-top: -2px!important;
    margin-left:0px!important;
    margin-right: 4px;
}
.loginPage{
    background:rgb(27, 29, 77);
    height: 100vh;
    width: 100;
    padding-top: 150px;
}

.summary-statistics {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    background: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
    margin-bottom: 10px;
}

.stat-box {
    flex: 1;
    min-width: 150px; /* Ensures even spacing */
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
    background: white;
    margin-right: 10px;
    margin: 5px;
}

.stat-title {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    display: block;
}

.stat-value {
    font-size: 18px;
    font-weight: bold;
    color: #007bff;
}





.tbodyclass td{

    white-space: nowrap;
}
.t3Fs td{
    line-height: 1.5;
}

#myTable td,#myTable th{
    padding: 6px;
}

.css-13cymwt-control{
    min-width: 250px!important;
}

.main_div_report .bp5-input-left-container{
top:5px!important
}

/* .mainlocationcontainer  table {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}

.mainlocationcontainer  th, td {
    padding: 10px;
    font-family: 'Roboto', sans-serif;
}

.mainlocationcontainer  h2, h3 {
    font-weight: 600;
    color: #2a2a6b;
}

.mainlocationcontainer  .primary-coupon-row {
    background-color: #e0f7fa;
    box-shadow: 0px 0px 5px rgba(56, 142, 60, 0.3);
}

.mainlocationcontainer  .info-icon {
    color: #5f6368;
    cursor: pointer;
}

.mainlocationcontainer   .info-icon:hover {
    color: #2a2a6b;
}

.mainlocationcontainer  button {
    background-color: #90caf9;
    color: #ffffff;
    border-radius: 4px;
    padding: 8px 12px;
}

.mainlocationcontainer  button:hover {
    background-color: #42a5f5;
} */




#stylestyle .row-full-width .bp5-icon{
color:#5f6368!important;
}
#stylestyle .row-full-width  .bp5-icon:hover{
    color: #000!important;
}

#stylestyle  .bp5-icon:hover{
    color: #e2e2e2!important;
}

.bp5-portal  .bp5-icon:hover{
    color: #cacaca!important;
}


#stylestyle table {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}

#stylestyle th, td {
    padding: 10px;
    font-family: 'Roboto', sans-serif;
}

#stylestyle h2, h3 {
    font-weight: 600;
    color: #2a2a6b;
}

#stylestyle .primary-coupon-row {
    background-color: #e0f7fa;
    box-shadow: 0px 0px 5px rgba(56, 142, 60, 0.3);
}

#stylestyle .info-icon {
    color: #5f6368;
    cursor: pointer;
}

#stylestyle  .info-icon:hover {
    color: #2a2a6b;
}

#stylestyle button {
    background-color: #90caf9;
    color: #ffffff;
    border-radius: 4px;
    padding: 8px 12px;
}

#stylestyle button:hover {
    background-color: #42a5f5;
}




.dashboardDiv .bp5-input:disabled, .bp5-input.bp5-disabled{
    color: #000!important;
}

.float-right{
    float: right!important;
}
.bp5-control{
    font-weight: 600!important;
}
.form-field input[type=color]{
padding: 0px;
padding-right:0px!important
}

.table1{
    margin: auto;
    width: 68%;
    border-collapse: collapse;
}
.mt-10{
    margin-top: 30%;
}
.alarmReports{
margin-left: 20px;
}
.UserList{
    margin-top: 20px;
    width:50%;
    text-align: left;
    margin: auto;
}

.labelzxc label{
font-weight: 500!important;
margin: 10px 0px;
}

.font-20{
    font-size: 20px!important;
}


.selectTimezon{
    width: 160px;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
}
.logo{
    margin-top: 10px!important;
    width: 25%!important;
}

.dashboardDiv{
   display: inline-block!important;
}

.form-field > .bp5-heading{
    font-weight: 700!important;
    font-family: sans-serif!important;
    font-size: 18px!important;
    line-height: 21px!important;
}
.form-field input[type=file]{
    padding-left: 0px!important;
}
.d-flex{
    display: flex;
    line-height: 1.8;
}

.feedtypecss {
    font-size: 18px!important;
    font-weight: 600!important;
    padding: 2px;
}
body{
    background: #e5e5e5;
}
.bg-white{
    background-color: #fff;
}
.form-field {
    margin: 20px 0px 20px 0px;
}

.m-1{
    margin: 2px!important;
}
.mr-1{
    margin: 0px 0px 0px 2px!important;
}
.mt-mb-10{
    margin-top: 10px;
    margin-bottom: 10px;
}

/* .bp5-input{
    line-height: 18px!important;
    padding: 5px 10px!important;
} */

.mt-10{
    margin-top: 10px!important;
}
.pb-40{
 padding-bottom: 40px!important;
}

.roleDropdown{
    width:103px;
    font-size: 17px;
    background: rgb(52, 52, 243);
    color: #fff;
    border-radius: 4px;
    padding: 4px;
}

.busiteDropdown{
    width:150px;
    font-size: 17px;
    background: rgb(61, 61, 226);
    color: #fff;
    border-radius: 4px;
    padding: 4px;
}

.busiteDropdown option:disabled{
    color: #a5a3a3;

}

.description{
    line-height: 24px;
    margin-bottom: 5px;
    color: #818898;
    font-size: 16px;
    font-family: monospace;
}

.min-h-27{
    border-radius: 19px!important;
    min-height: 27vh;

}
.p-1{
    padding: 10px;
}
.font-20{
    font-size: large!important;
}

.left{
    text-align: left;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 2px!important;
}

.left1{
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 2px!important;
}
.bp5-input{
    border-radius: 3px!important;
}
.m-3{
    margin: 3px;
}

.w-71{
    width: 70%;
}
.w-31{
    width: 30%;
}




@media only screen and (min-device-width : 220px) and (max-device-width : 480px) {

    .row-full-width{
        width: 100%; 
        display: block;
     }
     .row-full-width-color{
        width: 100%; 
        display: block;
     }
     
     .w-33{
         width: 100%;
     }
     .w-50{
         width: 100%;
     }
     .wi-50{
        width: 100%;

    }

.loginCard{
    width:98%;
    margin-top: auto;
    margin: auto;
}

.home{
    width:100%;
    overflow: auto;
}

.UserList{
    margin-top: 20px;
    width: 98%;
    text-align: left;
    margin: auto;
}

.alarmReports{
    margin-top: 20px;
    text-align: left;
    width: 80%;
}

}


@media only screen and (min-device-width : 480px) and (max-device-width : 800px) {

    .row-full-width-color{
        width: 100%; 
        display: block;
     }

    .row-full-width{
        width: 100%; 
        display: block;
     }
     
     .w-33{
         width: 100%;
     }
     .w-50{
         width: 100%;
     }
     .wi-50{
        width: 100%;

    }

    

    .loginCard{
        width:98%;
        margin-top: auto;
        margin: auto;
        box-shadow: 0 0 28px 1px #0237d1d4;
    }

    .home{
        width:max-content;
    }
    .UserList{
        margin-top: 20px;
        width: 70%;
        text-align: left;
        margin: auto;
    }

    .alarmReports{
        margin-top: 20px;
        text-align: left;
        width: 45%;
    }
}

@media only screen and (min-device-width : 800px) and (max-device-width : 1300px) {

    .row-full-width{
        width: 100%; 
        display: flex;
     }
     .row-full-width-color{
        width: 100%; 
        display: block;
     }
     
     .w-33{
         width: 50%;
         /* padding-left: 10px; */
     }
     .w-50{
        width: 50%;
        padding-left: 7px;
    }
    .wi-50{
        width: 50%;

    }


    .loginCard{
        width:40%;
        margin-top: auto;
        margin: auto;
        box-shadow: 0 0 28px 1px #0237d1d4;
    }

    .home{
        width:max-content;
    }

    .alarmReports{
        margin-top: 20px;
        text-align: left;
        width: 38%;
    }
    
}


@media only screen and (min-device-width : 1300px) and (max-device-width : 3000px) {

    .row-full-width{
        width: 100%; 
        display: flex;
     }
     .row-full-width-color{
        width: 100%; 
        display: flex;
     }
     
     .w-33{
         width: 33%;
         /* padding-left: 10px; */
     }
     .w-50{
         width: 50%;
         padding-left: 7px;
     }
     .wi-50{
        width: 50%;

    }
    .loginCard{
        width:25%;
        margin-top: auto;
        margin: auto;
        box-shadow: 0 0 28px 1px #0237d1d4;
    }

    .home{
        width:100%;
    }


    .alarmReports{
        margin-top: 20px;
        text-align: left;
        width: 38%;
    }
}
             

.bp5-button-text{
    white-space: nowrap;
}

.main_div_Archive{
    width:70%;
   margin: auto;
   margin-top: 100px;
   text-align: left;
}

.main_div_report{
    width:80%;
    margin: auto;
    margin-top: 5%;
    /* overflow: auto; */
    text-align: left;
}

.archivedata{
    margin-top: 10px;
}

.danger{
    color: red;
}

.selectInput{
    background: linear-gradient(94.46deg,#0d47f0,#3f79cb);
    color: #e5e5e5;
    box-shadow: 1px 5px 10px rgb(13 71 240/24%);
    padding: 2px;
    margin: 5px;
}

.selectInput option{
    color: #000;
}
.dateInput{
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #dfdfdf7a;
    margin: 5px;
}
.buttonInput{
    background: linear-gradient(94.46deg,#0d47f0,#3f79cb);
    color: #e5e5e5!important;
    box-shadow: 1px 5px 10px rgb(13 71 240/24%);
    padding: 5px!important;
    margin: 5px;
}

.buttonInputX{
    /* background: linear-gradient(94.46deg,#0d47f0,#3f79cb); */
    /* color: #e5e5e5!important; */
    box-shadow: 1px 5px 10px rgb(13 71 240/24%);
    padding: 5px!important;
    margin: 5px;
    margin-left: 0px;
}


.buttonInput .bp5-button-text{
    color: #e5e5e5!important;
}


.bp5-button.bp5-intent-primary{
    background: -webkit-linear-gradient(355.54deg, #0d47f0, #3f79cb)!important;
    border-radius: 5px;
}

/* .bp5-input-group{
    margin: unset!important;
} */