select,input{
    border: 1px solid #0000005c;
    border-radius: 4px;
    padding: 4px;
    color: #000000c4;
}
.rightblockDefaultImg{
    overflow:auto ;
    text-align: center;
    font-size: 18px;
 
}

.rightInput{
    width:100px!important;
}
.label{
    text-align: justify;
    display: unset!important;
     max-width:unset!important; 
     margin-bottom:unset!important; 
     font-weight: unset!important;
}
.rightblockDefaultImg img{
   display: block;
   margin: auto;
   width: 100px;
}

.mapDivs{
    display:grid;
    border:1px solid #ebeaea;
    padding:8px;
    transition:ease-in;
 
}
.red{
    color: red;
}



@media only screen and (min-width: 1200px) and (max-width: 3000px) { 

.group-btn{
    float:left;
    width:20%;
    margin-right:2px;
}

.PointForm{
    border-left:1px solid #fff;
    display:block;
    width:100%;
    margin:auto;
    min-height:736px;
    background:rgb(246 255 255 / 27%);
    padding:10px;
    border-radius:5px;
    margin-top:20px;
    text-align: left;
}

    .BusiessListCss{
        border-right:1px solid #fff;
        display:block;
        width:100%;
        height:736px;
        overflow:auto;
         background:rgb(246 255 255 / 27%);
         padding:10px;
         border-radius:5px;
         margin-top:20px;
    }

    .container{
    
        width: 70%;
        display: flex;
        margin: auto;
        margin-top: 10px;
    }
    
    .col-3{
        width: 30%;
    }
    .col-9{
        width: 70%;
    }
    
    }




    @media only screen and (min-width: 700px) and (max-width: 1200px) { 
        .group-btn{
            float:left;
            width:25%;
            margin-right:2px;
        }
        .PointForm{
            border-left:1px solid #fff;
            display:block;
            width:100%;
            margin:auto;
            height:736px;
            background:rgb(246 255 255 / 27%);
            padding:10px;
            border-radius:5px;
            margin-top:20px;
            text-align: left;
        }
        .BusiessListCss{
            border-right:1px solid #fff;
            display:block;
            width:100%;
            height:736px;
            overflow:auto;
             background:rgb(246 255 255 / 27%);
             padding:10px;
             border-radius:5px;
             margin-top:20px;
        }
    
        .container{
        
            width: 97%;
            display: flex;
            margin: auto;
            margin-top: 10px;
        }
        
        .col-3{
            width: 30%;
        }
        .col-9{
            width: 70%;
        }
        
        }


        @media only screen and (min-width: 200px) and (max-width: 700px) { 
            .group-btn{
                float:left;
                width:30%;
                margin-right:2px;
            }
            .PointForm{
                border-right:1px solid #fff;
                display:block;
                width:100%;
                margin:auto;
                height:736px;
                background:rgb(246 255 255 / 27%);
                padding:10px;
                border-radius:5px;
                margin-top:20px;
                text-align: left;
            }
            .BusiessListCss{
                border-left:1px solid #fff;
                display:block;
                width:100%;
                height:736px;
                overflow:auto;
                 background:rgb(246 255 255 / 27%);
                 padding:10px;
                 border-radius:5px;
                 margin-top:20px;
            }
        
            .container{
            
                width: 100%;
                display: unset;
                margin: auto;
                margin-top: 10px;
            }
            
            .col-3{
                width: 100%;
            }
            .col-9{
                width: 100%;
            }
            
            }




.listtab{
    display: flow-root;
    padding: 4px;
    background-color: #fff;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 1px;
}

.listtab:hover{
    background-color: #ebeaea;
}

.m-1{
    margin-left: 2px;
}

input:read-only {
    background-color: rgb(236, 236, 236);
  }