.css-p5ku4d{
    width: 95%!important;
}

.coupons{
    padding: 10px;
}


.checkin-main{
    display:flex;
    margin:auto;
    padding:15px 0px 10px 10px;
    width:100%;
    min-height: 94vh;
}

.waivertext{
    background-color: #fff;
    color: #000;
}
.container-fluid .col-65{
    height: 90vh!important;
    overflow: auto;
}
.container-fluid .col-35{
    height: 90vh!important;
    overflow: auto;
}
.pointer{
    cursor: pointer;
}
.couponlist{
    background-color: #cbd3d9;
    line-height: 2;
    padding: 5px;
    font-size: medium;
    border: 1px solid #878787;
    border-radius: 4px;
    overflow:auto;
}
.icontodayscheckins:hover{
    background-color: #0D47F0;
    color: rgb(255, 255, 255);
    font-size: medium;
    font-size: 19px;
    border-radius: 2px;
    transition: 0.4s;
}

.col-100 .listcss p{
    font-size: 25px!important;
    padding: 2px 10px 2px 10px;
    border-bottom: 1px solid #ebebeb;
}
.containerInfo{
    display: flex;
}

.btn-group{
    display: block!important;
    text-align: center;
    margin-top: 30px;
    /* position: absolute; */
    /* bottom: 2px; */
}
.overflow-scrollx{
    overflow: auto;
}
.col-65 select {
    padding: 3px;
    margin-top: 2px;
    font-size: 14px;
    color: #000;
    border: 0px;
    text-align: left;
   
}
.col-100 select {
    padding: 3px;
    margin-top: 2px;
    font-size: 20px;
    border: 0px;
    text-align: center;
   
}
.col-35  {
   height: 800px;
}
.pclass{
    text-align: center;
}

.loader{
    width: 80px;
    height: 16px;
    margin: auto;
    text-align: center;
}

.loaderdiv{
    height: 800px;
    display: flex;
    width: 100%;
}


.imgdiv{
    overflow: auto;
    margin-top: 10px;
    height: 350px;
    border: 3px solid #002;
    border-radius: 4px;
    display: flex;
    padding: 1px!important;
}

.topLogos img{
   margin: auto;
   width: 100%;
   padding: 0px;
} 
@media only screen and (min-width: 1191px) and (max-width: 3000px) {   
    .searchInput{
        margin:0px 10px 8px 4px;
        padding:10px;
        width:96%;
        border-radius:16px;
    }
    .searchInputout{
        margin:0px 10px 0px 4px;
        padding:10px;
        font-size: 25px;
        height: 55px;
        width:90%;
        border-radius:5px;
    }

    .btn-p-f{
        padding: 19px;
        font-size: 18px;
        margin: 2px;
    }
    .topLogos{
        width: 14%;
        display: grid;
    
    }  
    .col-65 select {
        padding: 3px;
        margin-top: 2px;
        font-size: 14px;
        color: #000;
        border: 0px;
        text-align: left;
       
    }
    .col-100 select {
        padding: 3px;
        margin-top: 2px;
        font-size: 20px;
        border: 0px;
        text-align: center;
       
    }
    .gm-main{
        display:flex;
        margin:auto;
        margin-top:10px;
        width:100%;
        min-height: 800px;
    }
    .col-65{
        overflow:hidden ;
        width: 70%;
        background-color: rgb(250, 250, 250);
        margin-right: 0px;
        border: 3px solid #000;
        border-radius: 5px;
        position: relative;
    }
 

    .col-100{
        overflow:hidden ;
        width: 70%;
        background-color: rgb(250, 250, 250);
        margin: auto;
        margin-top: 0px;
        margin-bottom: 0px;
        border: 3px solid #000;
        border-radius: 5px;
        position: relative;
    }
 
    .col-35{
        overflow:auto ;
        width: 30%;
        /* margin-right: 20px; */
        background-color: rgb(255, 255, 255);
        border: 3px solid #000;
        border-radius: 5px;
    }
    
    .col-35 p {
        padding: 8px;
        border: 1px solid #000;
        font-size:medium;
        margin: 0px;
    }
    
    .col-35 p:hover {
    background-color: aliceblue;
    }
    
    .col-35 p a{
       color: #000;
       text-decoration: none;
    }
    


        .liveupdateinfo{
        display: flex;
        font-size: x-large;
        }
        .liveupdateinfo div{
   padding: 10px;
        }

        .minmaximg{
            width: 100%;
           
        
        }

        .imgdiv{
            overflow: auto;
            margin-top: 10px;
            height: 350px;
            border: 3px solid #002;
            border-radius: 4px;
            display: flex;
        }
    }



    /* ipad */
    @media only screen and (min-width: 760px) and (max-width: 1190px) { 
        .listAboveText{
            font-size:20px!important;
        } 
        #header_dm_block{
            display: flex!important;
            overflow: auto!important;
          } 
          .searchInput{
            margin:0px 10px 8px 4px;
            padding:10px;
            width:96%;
            border-radius:16px;
        }
        .searchInputout{
            margin:0px 10px 0px 4px;
            padding:10px;
            font-size: 25px;
            height: 55px;
            width:90%;
            border-radius:5px;
        }
        .InfoboxLeft{
            width: 50%;            
        }
        .listcss{
            max-height:500px;
            overflow:auto
        }
        .btn-p-f{
            padding: 10px;
            font-size: 16px;
            margin: 2px;
        }
        .topLogos{
            width: 20%;
            display: grid;
        
        }  
        .col-65 select {
            padding: 3px;
            margin-top: 2px;
            font-size: 13px;
            color: #000;
            border: 0px;
            text-align: left;
           
        }
        .col-100 select {
            padding: 3px;
            margin-top: 2px;
            font-size: 18px;
            border: 0px;
            text-align: center;
           
        }
        .gm-main{
            display:flex;
            margin:auto;
            width:100%;
            min-height: 800px;
        }
        .col-65{
            overflow:hidden ;
            width: 70%;
            background-color: rgb(250, 250, 250);
            margin-right: 0px;
            border: 3px solid #000;
            border-radius: 5px;
            position: relative;
        }
     

        .col-100{
            overflow:hidden ;
            width: 75%!important;
            background-color: rgb(250, 250, 250);
            margin: auto;
            margin-top: 0px;
            margin-bottom: 0px;
            border: 3px solid #000;
            border-radius: 5px;
            position: relative;
        }
     
        .col-35{
            overflow:auto ;
            width: 30%;
            /* margin-right: 20px; */
            background-color: rgb(255, 255, 255);
            border: 3px solid #000;
            border-radius: 5px;
        }
        
        .col-35 p {
            padding: 8px;
            border: 1px solid #000;
            font-size:small;
            margin: 0px;
        }
        
        .col-35 p:hover {
        background-color: aliceblue;
        }
        
        .col-35 p a{
           color: #000;
           text-decoration: none;
        }
            
    
    .liveupdateinfo{
            display: grid;
            font-size: medium;
            }
            .liveupdateinfo div{
       padding: 10px;
            }
    
            .minmaximg{
                width: 100%;
               
            
            }

            
.imgdiv{
    overflow: auto;
    margin-top: 10px;
    height: 280px;
    border: 3px solid #002;
    border-radius: 4px;
    display: flex;
    padding: 2px;
}
    
        }

        /* mobile */
    @media only screen and (min-width: 200px) and (max-width: 760px) { 
        #header_dm_block{
            display: flex!important;
            overflow: auto!important;
          }
        .listAboveText{
            font-size: 16px!important;
            margin: 0px!important;
        }
        .searchInput{
            margin:0px 10px 8px 4px;
            padding:10px;
            width:50%;
            border-radius:16px;
        }
        .searchInputout{
            margin:0px 10px 0px 4px;
            padding:10px;
            font-size: 25px;
            height: 55px;
            width:90%;
            border-radius:5px;
        }
        .selecttimeMobileButton{
            
  padding: 15px;

        }
        .signpicbuton{
            display: flex;
            justify-content: center;
        }
        .containerInfo{
            justify-content: center;
            margin-top: 3px;
        }
        .btn-p-f{
            padding: 12px;
            font-size: 16px;
            margin: 2px;
        }
        .topLogos{
            width: 20%;
            display: grid;
        
        }
    
        .col-65 select {
            padding: 3px;
            margin-top: 2px;
            font-size: 12px;
            color: #000;
            border: 0px;
            text-align: left;
           
        }
        .col-100 select {
            padding: 3px;
            margin-top: 2px;
            font-size: 12px;
            border: 0px;
            text-align: center;
           
        }
            .gm-main{
                display:grid;
                margin:auto;
                width:100%;
             
            }
            .col-65{
                overflow:hidden ;
                width: 100%;
                background-color: rgb(250, 250, 250);
                border: 3px solid #000;
                border-radius: 5px;
                position: relative;
            }

            .col-100{
                overflow:hidden ;
                width: 90%;
                margin: auto;
                margin-top: 0px;
                margin-bottom: 0px;                
                background-color: rgb(250, 250, 250);
                border: 3px solid #000;
                border-radius: 5px;
                position: relative;
            }
     
            .col-35{
                overflow:unset!important;
                width: 100%;
                background-color: rgb(255, 255, 255);
                border: 3px solid #000;
                border-radius: 5px;
                margin-top: 5px;
                height: 500px!important;
                margin-bottom: 20px;
            }
            
            .col-35 p {
                padding: 8px;
                border: 1px solid #000;
                font-size:small;
                margin: 0px;
            }
            
            .col-35 p:hover {
            background-color: aliceblue;
            }
            
            .col-35 p a{
               color: #000;
               text-decoration: none;
            }
            
            .minmaximg{
                width: 100%;
       
    }
            
     
            .liveupdateinfo{
                display: flex;
                font-size: small;
                justify-content: center;
                }
                .liveupdateinfo div{
           padding: 3px;
                }

                .imgdiv{
                    overflow: auto;
                    height: 280px;
                    border: 3px solid #002;
                    border-radius: 4px;
                    display: flex;
                    padding: 0px!important;
                }
                .listcss{
                    overflow: auto;
                    height: 380px;
                }
            }



            