body {
  margin: 0;
  font-family:sans-serif!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bp5-navbar{
  height: 51px!important;
}

