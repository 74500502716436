
.App {
  text-align: center;
  /* margin-top: 51px; */
}
select{
  padding: 5px 10px!important;
}
.p-0{
  padding: 0px!important;
}

.positionofreaddocs{

  position: absolute;
  right: 0px;
  bottom: 0px;
  color: #000000;
  z-index: 10000000;
  font-weight: 600;
  background: #11ff01;
  padding: 4px;
  /* border-radius: 50%; */
  /* rotate: 89deg;
}

.container-fluid .bp5-elevation-4{
  box-shadow: unset!important;
}
.bp5-table-quadrant-body-container {
  /* overflow-y: auto; */
  overflow-x: hidden;
  /* height: 67vh; */
}
html {
  overflow-y: scroll;
  overflow-y: overlay;
}
.bp5-input-group .bp5-button{
  margin: unset!important;
}

.bp5-input{
  font-size: 14px!important;
  line-height: 24px!important;
}

.home  .min-h-27{
  min-height: 33vh!important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
a button span {
      text-wrap: nowrap;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.bp5-read-only .bp5-button{
  margin: 0px!important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
