/*  */


.css-p5ku4d{
    width: 100%!important;

}

.contain-Main-ml input[type="number"]::-webkit-outer-spin-button,
.contain-Main-ml input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bp3-dialog{
    padding-bottom: 0px!important;
}


.contain-Main-ml select,.contain-Main-ml input[type="tel"]{
    text-align: center!important;
    height: 37px;
    font-size: 15px;
}
/* Firefox */
.contain-Main-ml input[type="tel"] {
  -moz-appearance: textfield;
  
}


.contain-Main-ml input[type=checkbox] ,.checkbox_color_change input[type=checkbox]{
    position: relative;
    cursor: pointer;
  }
  
  .contain-Main-ml input[type=checkbox]:before ,.checkbox_color_change input[type=checkbox]::before{
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 1px solid #99AFC1;
    border-radius: 3px;
    padding: 1px;
    background-color: #00ae5d;
  }
  
  .contain-Main-ml input[type=checkbox]:checked:before ,.checkbox_color_change input[type=checkbox]::before{
    background-color: #00ae5d;
  }
  
  .contain-Main-ml input[type=checkbox]:checked:after ,.checkbox_color_change input[type=checkbox]::after{
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  }
.contain-Main-ml input[type="number"]::-webkit-outer-spin-button,
.contain-Main-ml input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (min-width: 1191px) and (max-width: 3000px) { 

    .w-100{
   width: 20%;
    margin: auto;
    }
    .h22return{
        font-size:28px!important;
     }
    .bordercontainer{
        margin: 30px;
        border: 1px solid #cacaca;
        padding: 33px 11px 11px 11px;
    }

    .conicon{
        position: absolute;
        right: 5px;
        top: 6px;
        cursor: pointer;
    }
    .ml-logo-container-list{
    width: 35%;
    margin: auto;
    }
.labelml{
    border: 1px solid #000000;
    display: flex !important;
    margin: auto;
    margin-bottom: .2vh !important;
    padding: 2px;
    width: 14%;
}

.tnc_sign_up_block{
    width: 74%;
    margin: auto;
    font-size: 19px;
}

}

@media only screen and (min-width: 720px) and (max-width: 1191px) {   

    .w-100{
        width: 30%;
         margin: auto;
         }
    .h22return{
        font-size:25px!important;
     }
    .bordercontainer{
        margin: 10px;
        border: 1px solid #cacaca;
        padding: 8px;
    }
    .labelml{
        border: 1px solid #000000;
        display: flex !important;
        margin: auto;
        margin-bottom: .2vh !important;
        padding: 2px;
        width: 27%;
    }
    .conicon{
        position: absolute;
        right: 5px;
        top: 6px;
        cursor: pointer;
    }

    .tnc_sign_up_block{
        width: 78%;
        margin: auto;
        font-size: 16px;
    }
    .ml-logo-container-list{
        width: 40%;
        margin: auto;
        }
    
}

@media only screen and (min-width: 100px) and (max-width: 720px) {  
    .w-100{
        width: 50%;
         margin: auto;
         } 
    .contain-Main-ml .h3in{
        font-size: 19px!important;
        line-height: 30px!important;
    }
    
    .contain-Main-ml .h1in{
        font-size: 28px!important;
        line-height: 30px!important;
    }
    .h22return{
        font-size:20px!important;
     }
    .bordercontainer{
        margin: 10px;
        border: 1px solid #b1b1b1;
        padding: 8px;
    }


    .labelml{
        border: 1px solid #000000;
        display: flex !important;
        margin: auto;
        margin-bottom: .2vh !important;
        padding: 2px;
        width: 52%;
    }
    .conicon{
        position: absolute;
        right: 1px;
        top: 0px;
        cursor: pointer;
    }  
    .tnc_sign_up_block{
        width: 99%;
        margin: auto;
        font-size: 15px;
    }

    .ml-logo-container-list{
        /* width: 80%; */
        margin: auto;
        }
    
}
.main-auto-center .bp3-spinner{
    height: 100vh;
}
.rororor{
    -moz-transition: all 2s linear;
    -webkit-transition: all 2s linear;
    transition: all 2s linear;
}

.rotate.down{
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.contain-Main-ml{
    background-color:#ffffff ;
    color: #1E1D1B;
    min-height: 100vh;
    width: 100%;
}
.themeC{
    background-color:#ffffff ;
    color: #000000
}
.contain-Main-ml .ml-logo-container{
display: block;
text-align: center;
padding: 5px 0px 5px 0px;

}
.contain-Main-ml .bp3-heading{
    color: #080808!important;
}

.contain-Main-ml .main-ml-screen{
    text-align: center;
}

.contain-Main-ml .ml-logo{
width: 200px;
height: 100;
}

.contain-Main-ml .d-flex{
    display: inline-flex;
    align-items: center;
}
.contain-Main-ml .w-13{
    width: 150px;
    margin-bottom: 10px;
}

.contain-Main-ml label.bp3-label{
    margin-bottom: 2px!important;
}

.contain-Main-ml td, .contain-Main-ml th{
    background-color:#ffffff ;
    color: #000000!important;
}


.ml-logo-container-list table{
 margin: auto;
 
}


.contain-Main-ml .bp5-heading{
    background-color:#ffffff ;
    color: #000000!important;
}